/* ck regular */
@font-face {
  font-family: 'National2';
  src: url('fonts/credit-karma/National2Web-Regular.woff2') format('woff2');
}

/* ck medium */
@font-face {
  font-family: 'National2';
  font-weight: 400 500;
  src: url('fonts/credit-karma/National2Web-Medium.woff2') format('woff2');
}

/* ck bold */
@font-face {
  font-family: 'National2';
  font-weight: 600 700;
  src: url('fonts/credit-karma/National2Web-Bold.woff2') format('woff2');
}

/* Summer */

/* regular */
@font-face {
  font-family: 'Avenir';
  src: url('fonts/summer/AvenirNextLTW01-Regular.woff') format('woff'),
    url('fonts/summer/AvenirNextLTW01-Regular.ttf') format('truetype');
}

/* italic */
@font-face {
  font-family: 'Avenir';
  font-style: italic;
  src: url('fonts/summer/AvenirNextLTW01-Italic.woff') format('woff'),
    url('fonts/summer/AvenirNextLTW01-Italic.ttf') format('truetype');
}

/* medium */
@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: url('fonts/summer/AvenirNextLTW01-Medium.woff') format('woff'),
    url('fonts/summer/AvenirNextLTW01-Medium.ttf') format('truetype');
}

/* Demi */
@font-face {
  font-family: 'Avenir';
  font-weight: 600 700;
  src: url('fonts/summer/AvenirNextLTW01-Demi.woff') format('woff'),
    url('fonts/summer/AvenirNextLTW01-Demi.ttf') format('truetype');
}

/* heavay */
@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  src: url('fonts/summer/AvenirNextLTW01-Heavy.woff') format('woff'),
    url('fonts/summer/AvenirNextLTW01-Heavy.ttf') format('truetype');
}
