html,
body,
#__next {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  -webkit-overflow-scrolling: auto;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
